import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://fd76-103-48-143-71.ngrok.io/api/v1/vonage/',
  // baseURL: 'https://ed31-103-48-143-71.ngrok.io/api/v1/vonage/',
  timeout: 30000
})

http.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers['token'] = store.state.token
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 1) {
      return res
    } else {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(new Error(res.message || 'error'))
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        router.push('/user')
        return Promise.reject(new Error('未登录'))
      }
    }

    Message({
      message: error.response.data.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default http
